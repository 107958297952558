.ls-sharks {
  overflow-x: hidden;
  max-width: 100%;
}

.shark {
  border-width: 18.6px 10px 0 10px;
}

.register-page {
  overflow-x: hidden;
  max-width: 100%;
  background-color: #F3F3F3;
  min-height: 100vh;

  .register-header-content {
    background-color: white;
    padding: 16px 32px;
  }

  h1 {
    font-size: 24px;
  }

  .register-header {
    background-color: white;
  }


  .register-page-content {
    margin-top: 32px;
    padding: 32px;
  }
}
