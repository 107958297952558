.page-content {
  margin-top: 32px;
  padding: 32px;
}

.person-container {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 8px;

  .person {
    background-color: white;
    border-radius: 14px;
    padding: 10px 16px;
    flex: 1;
  }

  .cross {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    padding: 10px;
    background-color: white;
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
}
