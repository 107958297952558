@import "../../styling/base";


.history-master {
}

.history-pop {
  cursor: pointer;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $semi-bold;
  width: 36px;
  height: 36px;
  color: white;
  z-index: 9;
  margin: 8px 16px 0 16px;
  border-radius: 12px;
}

.history-content {
  position: absolute;
  background-color: white;
  left: 0;
  top: 52px;
  width: 100%;

  .history-items-collective {
    overflow-y: scroll;
    max-height: calc(100vh - 52px);
  }

  .history-item-container {

    //display: flex;
    padding: 8px 16px;
    word-break: break-word;

    span {
      margin-right: 6px;
    }

    .history-time {

      svg {
        margin-right: 6px;
      }
    }

    .history-item-details {

      span {
        font-weight: bold;
      }
    }
  }

  h1 {
    margin-bottom: 0;
    padding: 0 16px 6px;
    border-bottom: 1px solid gainsboro;
    font-size: 20px;
  }
}

.history-transition-enter {
  transform: scale(0, 0) translate(100%, 0px);
}

.history-transition-enter-active {
  transform: scale(1, 1) translate(0, 0);
  transition: all 0.3s ease-out;
}

.history-transition-exit {
  transform: scale(1, 1) translate(0, 0);
}

.history-transition-exit-active {
  transform: scale(0, 0) translate(100%, 0px);
  transition: all 0.2s ease-in;
}
