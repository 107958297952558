.card {
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  margin: 16px 32px;

  p {
    margin: 8px 0;
  }

  h4 {
    margin: 0;
    font-size: 16px;
  }
}

.cards {
}

.pills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.contributors {
  h1 {
    padding-bottom: 0 !important;
  }

  p {
    margin: 8px 0 0 0;
  }

  .pill {
    margin-top: 8px;
  }
}

.pill {
  border-radius: 10000px;
  font-weight: bold;
  text-align: center;
  width: auto;
  padding: 4px 32px;
  margin-right: 8px;
}

.red {
  background-color: #FF6C6C;
}

.green {
  background-color: #6CFF8A;
}