.no-access-page-container {
    padding: 16px 32px;
}

.nap-bottom {
    background-color: #F3F3F3;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.nap-bottom-content {
    margin-top: 32px;
    padding: 32px 16px 16px 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 300px);
    align-items: center;
}

.firebaseui-idp-button {
    border-radius: 16px !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    width: 90% !important;
    font-family: 'Work Sans', sans-serif !important;
    min-height: unset !important;
    max-width: unset !important;
}

.firebaseui-idp-text {
    font-size: medium !important;
}

@media only screen and (min-width: 768px) {
    .firebaseui-idp-button {
        max-width: 300px !important;
    }
}

.firebaseui-list-item {
    margin-bottom: 0 !important;
    margin-top: 16px !important;
}

.firebaseui-idp-list {
    margin: 0 !important;
}

#firebaseui_container {
    width: 100%;
}

.firebaseui-card-content {
    padding: 0 !important;
}

.dm .no-access-page-container {
    color: white;
}

.dm .nap-bottom {
    background-color: #353535;
    color: white;
}