$mainColor: #6523F3;
$secondaryColor: #E5DCF9;
$titleGrey: #ABABAB;
$backgroundGrey: #F3F3F3;
$textGrey: #838383;

$title-size-semi: 18px;
$semi-bold: 600;

@media only screen and (min-width: 768px) {
  .ls-button {
    max-width: calc(300px - (16px * 2));
  }
}

.ls-button {
  background-color: white;
  color: black;
  text-align: center;
  padding: 8px 16px;
  border-radius: 16px;
  cursor: pointer;
}

.width-80 {
  width: 80%;
}


.ls-button-primary {
  color: white;
  background-color: $mainColor;
}

.or {
  font-weight: $semi-bold;
  text-align: center;
  padding: 16px;
}