$mainColor: #6523F3;
$secondaryColor: #E5DCF9;
$titleGrey: #ABABAB;
$backgroundGrey: #F3F3F3;
$textGrey: #838383;

$title-size-semi: 18px;
$semi-bold: 600;

.tag-page {
  overflow-x: hidden;
  max-width: 100%;
  background-color: #F3F3F3;
  min-height: 100vh;

  .tags-without-bottom {
    min-height: calc(100vh - 41px - 32px);
  }

  .buttons {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .button-1 {
      background-color: white;
    }
  }

  .ls-sharks {
    overflow-x: hidden;
    max-width: 100%;
  }

  .tag-explanation {
    padding: 16px 32px;
    background-color: white;
  }

  h1 {
    font-size: 24px;
  }

  .tag-setup {
    margin-top: 16px;

    h1 {
      margin-top: 32px;
      padding: 16px 32px;
    }

    svg {
      font-size: medium;
    }


  }

  .shark {
    border-width: 18.6px 10px 0 10px;
  }

  @media only screen and (min-width: 768px) {
    .shark {
      border-width: 26.0px 15px 0 15px;
    }
  }

  .tags-margin {
    margin-bottom: 212px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    padding: 0 16px;

    .tag-wrapper {
      display: flex;
    }

    .tag {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      cursor: pointer;
      margin: 0 8px 16px 8px;
      font-size: 24px;
      width: 52px;
      height: 52px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 26px;
    }

    .tag-selected {

    }

    .tag-selected {
      animation: wiggle 1s infinite;
    }

    @keyframes wiggle {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-10deg);
      }
      50% {
        transform: rotate(20deg);
      }
      75% {
        transform: rotate(-15deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }

    .tag-delete {
      font-size: 14px;
      display: flex;
      position: absolute;
      transform: translate(36px, -10px);
      width: 28px;
      height: 28px;
      background-color: white;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.23);
      cursor: pointer;
    }

    .tag-plus {
      font-weight: bold;
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}
