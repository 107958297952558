$mainColor: #6523F3;
$secondaryColor: #E5DCF9;
$titleGrey: #ABABAB;
$backgroundGrey: #F3F3F3;
$textGrey: #838383;

$title-size-semi: 18px;
$semi-bold: 600;

.ls-header-filler {
  height: 52px;
}

.pop {
  animation: pop .3s ease-out;
}

.ls-header {
  z-index: 2;
  height: 52px;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in;

  .ls-header-title {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: $semi-bold;
    font-size: $title-size-semi;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 120px);
    margin-left: auto;
    margin-right: auto;
  }

  .hamburger-icon-div {
    svg {
      position: absolute;
      padding: 16px;
      cursor: pointer;
    }
  }
}

body {
  background-color: white;
}

.menu-gesture-box {
  position: fixed;
  height: 100%;
  width: 14px;
  z-index: 5;
}

.ls-list {

  .item {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f7f7f7;
    cursor: pointer;
    transition: all .42s cubic-bezier(.25, .8, .4, 1);

    &:active {
      -webkit-box-shadow: inset 0 1px 7px -2px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: inset 0 1px 7px -2px rgba(0, 0, 0, 0.25);
      box-shadow: inset 0 1px 7px -2px rgba(0, 0, 0, 0.25);
    }

    .name {
      color: black;
      padding: 16px;
      //font-weight: $semi-bold;

      a {
        text-decoration: underline;
        font-weight: 600;
        word-break: break-all;
      }
    }

    .list-item-tag {
      margin-left: auto;
      padding-right: 16px;
    }
  }

  .item-edit-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .edit-cancel {
    font-weight: $semi-bold;
    margin-right: 16px;
  }

  .item-edit-superior {
    animation: pop .3s ease-out;
  }

  @keyframes pop {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.07);
    }
    100% {
      transform: scale(1);
    }
  }

  .input-group {
    display: flex;
    background-color: #F8F8F8;
    margin-bottom: 52px;
  }

  input {
    outline: none;
    width: calc(100% - 32px - 54px);
    padding: 16px;
    font-size: medium;
    border: none;
    background-color: unset;
  }
}

.fade {
  z-index: 4;
  left: 0;
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 10;
  transition: opacity .4s, visibility .4s;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.delete-container {
  position: fixed;
  width: 100%;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ls-delete {
  cursor: pointer;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  font-weight: $semi-bold;
}

/**
 * Transitions
 */
.transition-group {
}

.fade-enter {
  opacity: 0;
  height: 0;
}

.fade-enter-active {
  opacity: 1;
  height: 52px;
  background-color: $backgroundGrey;
  transition: all 0.5s cubic-bezier(.36, -0.64, .34, 1.76);
}

.fade-exit {
  opacity: 1;
  height: 52px;
  //transform: scale(1, 1);
}

.fade-exit-active {
  height: 0;
  //transform: scale(0, 0);
  transition: all 0.5s ease-in;
}

.border-bottom {
  border-bottom: 1px solid #f7f7f7;
}

.ls-sharks {
  position: absolute;
  display: flex;
  .shark {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 34.6px 20px 0 20px;
    border-color: white transparent transparent transparent;
  }
}

.ls-empty {
  background-color: $backgroundGrey;
  position: absolute;
  bottom: 0;
  height: calc(50% - 22px);
  width: 100%;
  //transition: height 0.4s ease-out;
  overflow: hidden;

  .container {
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100% + 16px);
  }

  .subtitle {
    color: #ababab;
    padding: 16px;
    width: 300px;
    text-align: center;
  }
}

.slide-enter {
  height: 0;
}

.slide-enter-active {
  height: calc(50% - 22px);
  transition: all 0.6s ease-out 0.5s;
}

.slide-exit {
  height: calc(50% - 22px);
}

.slide-exit-active {
  height: 0;
  transition: all 0.6s cubic-bezier(.73, .1, .56, .87) 0.5s;
}

.move-icon {
  position: fixed;
  padding: 16px;
  cursor: pointer;
  right: 0;
  z-index: 3;
}

.move-up {
  top: calc(52px);
  animation: indicateUp 0.8s ease-in-out;
}

@keyframes indicateUp {
  0% {
    transform: translateY(-52px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes indicateDown {
  0% {
    transform: translateY(52px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.move-down {
  bottom: 0;
  animation: indicateDown 0.8s ease-in-out;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ls-fab {
  box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #535353;
  font-size: 20px;
  cursor: pointer;
}

.tag-container-emoji {
  //padding: 10px 22px !important;
}

.tag-container {
  font-size: 20px;
  cursor: pointer;
  //position: absolute;
  //right: 0;
  display: flex;
  align-items: center;
  padding: 5px 0 5px 5px;

  .tags {
    padding: 8px 0;
    z-index: 1;
    transform-origin: -18px 0;
    margin: 8px 0 16px;
    transform: translate(-40px, 20px);
    background-color: white;
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.23);
    border-radius: 26px;
    display: inline-block;
    position: absolute;
  }

  .tag-button-selected {
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.23);
    background-color: white;
  }

  .tag-button {
    display: flex;
    transition: all 0.2s ease-out;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    padding: 10px;
    width: 20px;
    height: 20px;
    overflow: hidden;
    color: #535353;
  }

  .tag {
    padding: 12px 8px;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    text-align: center;
  }

  .tag-selected {
    font-size: 18px;
  }

  .tags-enter {
    transform: scale(0, 0) translate(-40px, 20px);
  }

  .tags-enter-active {
    transform: scale(1, 1) translate(-40px, 20px);
    transition: all 0.3s ease-out;
  }

  .tags-exit {
    transform: scale(1, 1) translate(-40px, 20px);
  }

  .tags-exit-active {
    transform: scale(0, 0) translate(-40px, 20px);
    transition: all 0.2s ease-in;
  }
}

.user-select-none {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.auto-complete-menu {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.auto-complete-item {
  padding: 16px;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.19);
  font-size: 12px;
}

.auto-complete-item-highlighted {
  background-color: #fbfbfb;
}

.edit-tags-container {
  max-width: 100vw;

  display: flex;
  overflow: scroll;

  .ls-fab {
    margin: 3px;
  }

  .no-shadow {
    box-shadow: none;
    background: unset !important;
    background-color: unset !important;
  }
}

.ls-loading {
  position: absolute;
  width: 100%;
  height: calc(100% - 52px);
  display: flex;
  align-items: center;
  justify-content: center;

  .ls-spinner-container {
    width: 50%;
    height: 5px;
    background-color: #f1f1f1;
    overflow: hidden;

    .ls-spinner {
      height: 100%;
      width: 100%;
      transform: translateX(-100%);
      animation: spinner 1s ease-in-out infinite;
    }

    @keyframes spinner {
      0% {
        transform: translateX(-100%);
        background-color: #f34773;
      }
      75% {
        transform: translateX(0);
        background-color: #f3c464;

      }
      80% {
        transform: translateX(100%);
        background-color: #eaf350;
        width: 10%;
      }
      100% {
        background-color: #44f325;
        transform: translateX(100%);
      }
    }

  }
}


.appear-enter {
  transform: scale(0);
}

.appear-enter-active {
  transform: scale(1);
  transition: all 0.3s cubic-bezier(.09,1.2,.33,1.18);
}

.appear-exit {
  transform: scale(1);
}

.appear-exit-active {
  transform: scale(0);
  transition: all 0.2s ease-in;
}


// Dark mode
.dm {
  .item {
    border-bottom: 1px solid #484848;

    .name {
      color: white;
    }

    input {
      color: white;
    }

    .edit-cancel {
      color: #b7b7b7;
    }

    .ls-fab {
      background-color: #484848;
    }
  }

  .input-group {
    background-color: #454545;

    input {
      color: white;
    }

    .tag-button {
      color: #cccccc;
    }

    .tag-button-selected {
      color: white;
      background-color: #484848;
    }

    .tags {
      background-color: #484848;
    }
  }

  .ls-hamburger {
    background-color: #3f3f3f;

    .row {
      color: #f0f0f0;
      border-bottom: 1px solid #505050;
    }

    .row-secondary {
      background-color: #383838;
    }

    .ls-table-group {
      h4 {
        color: white;
      }

      .first {
        border-top: 1px solid #505050;
      }
    }

    .saved-list-select {
      background-color: #3f3f3f;
      color: white !important;
    }

    .saved-list {
      background-color: rgb(49, 49, 49);
      color: #f1f1f1;
      font-weight: normal;
    }

    .add-list {
      box-shadow: unset;
    }

    .selected-list-options .menu-sharks .menu-shark {
      border-color: transparent transparent #656565 transparent;
    }

    .menu-selected-list-options {
      background-color: #656565;
      color: white;
    }
  }

  .ls-header {
    background-color: #2f2f2f !important;
    border-bottom: 1px solid #585858;
  }

  .ls-empty {
    .container {
      background-color: #353535;
    }
  }

  .shark {
    border-color: #3f3f3f transparent transparent transparent;
  }

  .fade-enter-active {
    background-color: #4d4d4d;
  }

  .dialog {
    background-color: #484848;

    .dialog-title-container {
      h1 {
        color: white;
      }
    }

    .dialog-content {
      background-color: #636363;

      p {
        color: white;
      }

      input {
        background-color: unset;
        color: white;
      }
    }

    .button-1 {
      background-color: #484848;
      color: white !important;
    }
  }
}