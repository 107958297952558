body {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

@media only screen and (min-width: 768px) {
  .ls-button {
    max-width: calc(300px - (16px * 2)); } }

.ls-button {
  background-color: white;
  color: black;
  text-align: center;
  padding: 8px 16px;
  border-radius: 16px;
  cursor: pointer; }

.width-80 {
  width: 80%; }

.ls-button-primary {
  color: white;
  background-color: #6523F3; }

.or {
  font-weight: 600;
  text-align: center;
  padding: 16px; }

body {
  font-family: 'Work Sans', sans-serif;
  background-color: #F5F5F5;
  margin: 0;
  padding: 0; }

input {
  font-family: 'Work Sans', sans-serif; }

.gray {
  background-color: #F5F5F5; }

.landing-page {
  overflow-x: hidden; }
  .landing-page h1 {
    font-size: 32px;
    margin: 0;
    padding: 64px 64px 0 64px;
    text-align: center; }
  .landing-page h2 {
    padding: 0 32px 16px 32px;
    text-align: center;
    font-size: large;
    margin: 16px 0 0 0; }
  .landing-page .content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .landing-page .content #logo {
      margin-top: 48px;
      width: 100px; }

.sharks {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  max-width: 100%;
  overflow: hidden; }
  .sharks .shark {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 34.6px 20px 0 20px;
    border-color: #F5F5F5 transparent transparent transparent; }

.button-1 {
  padding: 10px 24px;
  border-radius: 20px;
  text-transform: uppercase;
  border: 1px solid #E9E9E9;
  color: #6523F3;
  font-weight: bold;
  cursor: pointer; }
  .button-1:hover {
    background-color: #fafafa; }

.button-secondary {
  color: #636363; }

@media only screen and (max-width: 374px) {
  .button-1 {
    font-size: small; } }

html, body {
    height: 100%;
}

.toggle {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.toggle:before {
    content: "";
    position: relative;
    top: 3px;
    left: 3px;
    width: 34px;
    height: 14px;
    display: block;
    background: #9A9999;
    border-radius: 8px;
    transition: background 0.2s ease;
}
.toggle span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    display: block;
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
    transition: all 0.2s ease;
}
.toggle span:before {
    content: "";
    position: absolute;
    display: block;
    margin: -18px;
    width: 56px;
    height: 56px;
    /*background: rgba(79, 46, 220, 0.5);*/
    border-radius: 50%;
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
    pointer-events: none;
}

.dm .toggle span {
    box-shadow: 0 3px 8px rgba(84, 84, 84, 0.5);
}

.toggle:before {
    /*background: #947ADA;*/
}

#cbx:checked + .toggle span {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
    /*box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);*/
}
#cbx:checked + .toggle span:before {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
    transition: all 0.4s ease;
}
.tag-page {
  overflow-x: hidden;
  max-width: 100%;
  background-color: #F3F3F3;
  min-height: 100vh; }
  .tag-page .tags-without-bottom {
    min-height: calc(100vh - 41px - 32px); }
  .tag-page .buttons {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .tag-page .buttons .button-1 {
      background-color: white; }
  .tag-page .ls-sharks {
    overflow-x: hidden;
    max-width: 100%; }
  .tag-page .tag-explanation {
    padding: 16px 32px;
    background-color: white; }
  .tag-page h1 {
    font-size: 24px; }
  .tag-page .tag-setup {
    margin-top: 16px; }
    .tag-page .tag-setup h1 {
      margin-top: 32px;
      padding: 16px 32px; }
    .tag-page .tag-setup svg {
      font-size: medium; }
  .tag-page .shark {
    border-width: 18.6px 10px 0 10px; }
  @media only screen and (min-width: 768px) {
    .tag-page .shark {
      border-width: 26.0px 15px 0 15px; } }
  .tag-page .tags-margin {
    margin-bottom: 212px; }
  .tag-page .tags {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 60px;
    padding: 0 16px; }
    .tag-page .tags .tag-wrapper {
      display: -webkit-flex;
      display: flex; }
    .tag-page .tags .tag {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      cursor: pointer;
      margin: 0 8px 16px 8px;
      font-size: 24px;
      width: 52px;
      height: 52px;
      background-color: #FFFFFF;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      border-radius: 26px; }
    .tag-page .tags .tag-selected {
      -webkit-animation: wiggle 1s infinite;
              animation: wiggle 1s infinite; }

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  50% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg); }
  75% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes wiggle {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  50% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg); }
  75% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }
    .tag-page .tags .tag-delete {
      font-size: 14px;
      display: -webkit-flex;
      display: flex;
      position: absolute;
      -webkit-transform: translate(36px, -10px);
              transform: translate(36px, -10px);
      width: 28px;
      height: 28px;
      background-color: white;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      border-radius: 50%;
      box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.23);
      cursor: pointer; }
    .tag-page .tags .tag-plus {
      font-weight: bold; }

a {
  text-decoration: none;
  color: inherit; }

.no-access-page-container {
    padding: 16px 32px;
}

.nap-bottom {
    background-color: #F3F3F3;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
}

.nap-bottom-content {
    margin-top: 32px;
    padding: 32px 16px 16px 16px;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: calc(100% - 300px);
    -webkit-align-items: center;
            align-items: center;
}

.firebaseui-idp-button {
    border-radius: 16px !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    width: 90% !important;
    font-family: 'Work Sans', sans-serif !important;
    min-height: unset !important;
    max-width: unset !important;
}

.firebaseui-idp-text {
    font-size: medium !important;
}

@media only screen and (min-width: 768px) {
    .firebaseui-idp-button {
        max-width: 300px !important;
    }
}

.firebaseui-list-item {
    margin-bottom: 0 !important;
    margin-top: 16px !important;
}

.firebaseui-idp-list {
    margin: 0 !important;
}

#firebaseui_container {
    width: 100%;
}

.firebaseui-card-content {
    padding: 0 !important;
}

.dm .no-access-page-container {
    color: white;
}

.dm .nap-bottom {
    background-color: #353535;
    color: white;
}
.ls-sharks {
  overflow-x: hidden;
  max-width: 100%; }

.shark {
  border-width: 18.6px 10px 0 10px; }

.register-page {
  overflow-x: hidden;
  max-width: 100%;
  background-color: #F3F3F3;
  min-height: 100vh; }
  .register-page .register-header-content {
    background-color: white;
    padding: 16px 32px; }
  .register-page h1 {
    font-size: 24px; }
  .register-page .register-header {
    background-color: white; }
  .register-page .register-page-content {
    margin-top: 32px;
    padding: 32px; }

.card {
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  margin: 16px 32px; }
  .card p {
    margin: 8px 0; }
  .card h4 {
    margin: 0;
    font-size: 16px; }

.pills {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.contributors h1 {
  padding-bottom: 0 !important; }

.contributors p {
  margin: 8px 0 0 0; }

.contributors .pill {
  margin-top: 8px; }

.pill {
  border-radius: 10000px;
  font-weight: bold;
  text-align: center;
  width: auto;
  padding: 4px 32px;
  margin-right: 8px; }

.red {
  background-color: #FF6C6C; }

.green {
  background-color: #6CFF8A; }

.ls-hamburger {
  position: fixed;
  width: 60%;
  max-width: 450px;
  min-width: 240px;
  background: #F8F8F8;
  z-index: 5;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  overflow: scroll;
  padding-bottom: 100px;
  transition: -webkit-transform 360ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 360ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 360ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 360ms cubic-bezier(0, 0, 0.2, 1) 0ms; }

.ls-hamburger::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */ }

.font-semi-bold {
  font-weight: 600; }

.hamburger-open {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.ls-table-group {
  margin-top: 32px; }
  .ls-table-group .row {
    cursor: pointer;
    padding: 16px;
    border-bottom: 1px solid #f1f1f1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .ls-table-group .row svg {
      color: #838383; }
  .ls-table-group .row-secondary {
    background-color: #f3f3f3; }
  .ls-table-group .with-icon {
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .ls-table-group .first {
    border-top: 1px solid #f1f1f1; }
  .ls-table-group h4 {
    margin-left: 16px;
    margin-bottom: 8px;
    color: #838383;
    font-size: 14px;
    text-transform: uppercase; }

.fade-menu-enter {
  height: 0; }

.fade-menu-enter-active {
  height: 156px;
  background-color: #F3F3F3;
  transition: all 0.35s ease-out; }

.fade-menu-exit {
  opacity: 1;
  height: 156px; }

.fade-menu-exit-active {
  height: 0;
  transition: all 0.2s ease-in; }

.dialog-background {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  cursor: pointer; }

.high-z-index {
  z-index: 15; }

.medium-z-index {
  z-index: 10; }

.low-z-index {
  z-index: 8; }

.dialog {
  border-radius: 10px;
  overflow: hidden;
  transition: all 1s ease-in;
  width: 80%;
  position: fixed;
  max-width: 340px;
  background-color: #F3F3F3;
  z-index: 16;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto; }
  .dialog .button-1 {
    opacity: 1;
    border: none; }
  .dialog h1 {
    font-size: 24px; }
  .dialog .dialog-title-container {
    text-align: center; }
  .dialog .dialog-content {
    padding: 16px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap; }
    .dialog .dialog-content span {
      font-weight: bold; }
    .dialog .dialog-content p {
      padding: 16px; }
  .dialog .buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.fixed-full-width {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 16; }

.loader-bar {
  width: 40px;
  height: 8px;
  position: relative;
  -webkit-animation: load 1s infinite;
          animation: load 1s infinite; }

.dialog-title input {
  border: none;
  font-size: medium;
  font-weight: bold;
  outline: none;
  padding: 10px 16px;
  margin-top: 10px;
  margin-bottom: 26px;
  background-color: #F3F3F3;
  border-radius: 16px; }

.chrome-picker {
  margin: 0 auto; }

@-webkit-keyframes load {
  0% {
    left: -20px; }
  100% {
    left: 100%; } }

@keyframes load {
  0% {
    left: -20px; }
  100% {
    left: 100%; } }

.dialog-enter {
  top: 150%; }

.dialog-enter-active {
  top: 50%;
  transition: all 0.3s ease-out; }

.dialog-exit {
  top: 50%; }

.dialog-exit-active {
  top: 150%;
  transition: all 0.2s ease-in; }

.display-none {
  display: none; }

.color-change-preview {
  width: 100px;
  height: 100px;
  margin: 16px auto;
  border-radius: 10px; }

.saved-lists {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0 8px; }
  .saved-lists .saved-list-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    max-width: 100%; }
  .saved-lists .saved-list {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    font-weight: 600;
    margin: 6px 8px;
    padding: 12px 24px;
    color: white;
    border-radius: 8px;
    transition: box-shadow 0.2s ease-out; }
    .saved-lists .saved-list:active {
      box-shadow: inset 2px 3px 51px 30px rgba(0, 0, 0, 0.15); }
  .saved-lists .saved-list-select {
    background-color: #F8F8F8;
    border-radius: 50%;
    padding: 4px;
    font-size: 12px;
    position: absolute; }

.selected-list-options {
  box-sizing: border-box;
  background-color: white;
  font-weight: 600;
  overflow: hidden;
  margin-top: 6px; }
  .selected-list-options .menu-selected-list-options {
    text-align: center;
    padding: 16px;
    cursor: pointer; }

.menu-slide-enter {
  height: 0; }

.menu-slide-enter-active {
  height: 51px;
  transition: all 0.3s ease-out; }

.menu-slide-exit {
  height: 51px;
  bottom: 0; }

.menu-slide-exit-active {
  height: 0;
  bottom: -20px;
  transition: all 0.3s cubic-bezier(0.73, 0.1, 0.56, 0.87); }

.italic {
  font-style: italic; }

@media only screen and (max-width: 400px) {
  .dialog h1 {
    font-size: large; } }

.switch-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 9px 0 16px;
  margin-bottom: 16px; }
  .switch-group .switch-label {
    margin-right: auto;
    font-weight: 600; }

.dm .switch-label {
  color: white; }

.ls-header-filler {
  height: 52px; }

.pop {
  -webkit-animation: pop .3s ease-out;
          animation: pop .3s ease-out; }

.ls-header {
  z-index: 2;
  height: 52px;
  position: fixed;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  transition: all 0.3s ease-in; }
  .ls-header .ls-header-title {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 120px);
    margin-left: auto;
    margin-right: auto; }
  .ls-header .hamburger-icon-div svg {
    position: absolute;
    padding: 16px;
    cursor: pointer; }

body {
  background-color: white; }

.menu-gesture-box {
  position: fixed;
  height: 100%;
  width: 14px;
  z-index: 5; }

.ls-list .item {
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #f7f7f7;
  cursor: pointer;
  transition: all 0.42s cubic-bezier(0.25, 0.8, 0.4, 1); }
  .ls-list .item:active {
    box-shadow: inset 0 1px 7px -2px rgba(0, 0, 0, 0.25); }
  .ls-list .item .name {
    color: black;
    padding: 16px; }
    .ls-list .item .name a {
      text-decoration: underline;
      font-weight: 600;
      word-break: break-all; }
  .ls-list .item .list-item-tag {
    margin-left: auto;
    padding-right: 16px; }

.ls-list .item-edit-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }

.ls-list .edit-cancel {
  font-weight: 600;
  margin-right: 16px; }

.ls-list .item-edit-superior {
  -webkit-animation: pop .3s ease-out;
          animation: pop .3s ease-out; }

@-webkit-keyframes pop {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.07);
            transform: scale(1.07); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes pop {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.07);
            transform: scale(1.07); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.ls-list .input-group {
  display: -webkit-flex;
  display: flex;
  background-color: #F8F8F8;
  margin-bottom: 52px; }

.ls-list input {
  outline: none;
  width: calc(100% - 32px - 54px);
  padding: 16px;
  font-size: medium;
  border: none;
  background-color: unset; }

.fade {
  z-index: 4;
  left: 0;
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 10;
  transition: opacity .4s, visibility .4s; }

.hidden {
  opacity: 0;
  visibility: hidden; }

.delete-container {
  position: fixed;
  width: 100%;
  bottom: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.ls-delete {
  cursor: pointer;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  font-weight: 600; }

/**
 * Transitions
 */
.fade-enter {
  opacity: 0;
  height: 0; }

.fade-enter-active {
  opacity: 1;
  height: 52px;
  background-color: #F3F3F3;
  transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76); }

.fade-exit {
  opacity: 1;
  height: 52px; }

.fade-exit-active {
  height: 0;
  transition: all 0.5s ease-in; }

.border-bottom {
  border-bottom: 1px solid #f7f7f7; }

.ls-sharks {
  position: absolute;
  display: -webkit-flex;
  display: flex; }
  .ls-sharks .shark {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 34.6px 20px 0 20px;
    border-color: white transparent transparent transparent; }

.ls-empty {
  background-color: #F3F3F3;
  position: absolute;
  bottom: 0;
  height: calc(50% - 22px);
  width: 100%;
  overflow: hidden; }
  .ls-empty .container {
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: calc(100% + 16px); }
  .ls-empty .subtitle {
    color: #ababab;
    padding: 16px;
    width: 300px;
    text-align: center; }

.slide-enter {
  height: 0; }

.slide-enter-active {
  height: calc(50% - 22px);
  transition: all 0.6s ease-out 0.5s; }

.slide-exit {
  height: calc(50% - 22px); }

.slide-exit-active {
  height: 0;
  transition: all 0.6s cubic-bezier(0.73, 0.1, 0.56, 0.87) 0.5s; }

.move-icon {
  position: fixed;
  padding: 16px;
  cursor: pointer;
  right: 0;
  z-index: 3; }

.move-up {
  top: calc(52px);
  -webkit-animation: indicateUp 0.8s ease-in-out;
          animation: indicateUp 0.8s ease-in-out; }

@-webkit-keyframes indicateUp {
  0% {
    -webkit-transform: translateY(-52px);
            transform: translateY(-52px); }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes indicateUp {
  0% {
    -webkit-transform: translateY(-52px);
            transform: translateY(-52px); }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes indicateDown {
  0% {
    -webkit-transform: translateY(52px);
            transform: translateY(52px); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes indicateDown {
  0% {
    -webkit-transform: translateY(52px);
            transform: translateY(52px); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.move-down {
  bottom: 0;
  -webkit-animation: indicateDown 0.8s ease-in-out;
          animation: indicateDown 0.8s ease-in-out; }

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ls-fab {
  box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #535353;
  font-size: 20px;
  cursor: pointer; }

.tag-container {
  font-size: 20px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px 0 5px 5px; }
  .tag-container .tags {
    padding: 8px 0;
    z-index: 1;
    -webkit-transform-origin: -18px 0;
            transform-origin: -18px 0;
    margin: 8px 0 16px;
    -webkit-transform: translate(-40px, 20px);
            transform: translate(-40px, 20px);
    background-color: white;
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.23);
    border-radius: 26px;
    display: inline-block;
    position: absolute; }
  .tag-container .tag-button-selected {
    box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.23);
    background-color: white; }
  .tag-container .tag-button {
    display: -webkit-flex;
    display: flex;
    transition: all 0.2s ease-out;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 26px;
    padding: 10px;
    width: 20px;
    height: 20px;
    overflow: hidden;
    color: #535353; }
  .tag-container .tag {
    padding: 12px 8px;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    text-align: center; }
  .tag-container .tag-selected {
    font-size: 18px; }
  .tag-container .tags-enter {
    -webkit-transform: scale(0, 0) translate(-40px, 20px);
            transform: scale(0, 0) translate(-40px, 20px); }
  .tag-container .tags-enter-active {
    -webkit-transform: scale(1, 1) translate(-40px, 20px);
            transform: scale(1, 1) translate(-40px, 20px);
    transition: all 0.3s ease-out; }
  .tag-container .tags-exit {
    -webkit-transform: scale(1, 1) translate(-40px, 20px);
            transform: scale(1, 1) translate(-40px, 20px); }
  .tag-container .tags-exit-active {
    -webkit-transform: scale(0, 0) translate(-40px, 20px);
            transform: scale(0, 0) translate(-40px, 20px);
    transition: all 0.2s ease-in; }

.user-select-none {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.auto-complete-menu {
  display: -webkit-flex;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap; }

.auto-complete-item {
  padding: 16px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.19);
  font-size: 12px; }

.auto-complete-item-highlighted {
  background-color: #fbfbfb; }

.edit-tags-container {
  max-width: 100vw;
  display: -webkit-flex;
  display: flex;
  overflow: scroll; }
  .edit-tags-container .ls-fab {
    margin: 3px; }
  .edit-tags-container .no-shadow {
    box-shadow: none;
    background: unset !important;
    background-color: unset !important; }

.ls-loading {
  position: absolute;
  width: 100%;
  height: calc(100% - 52px);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .ls-loading .ls-spinner-container {
    width: 50%;
    height: 5px;
    background-color: #f1f1f1;
    overflow: hidden; }
    .ls-loading .ls-spinner-container .ls-spinner {
      height: 100%;
      width: 100%;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-animation: spinner 1s ease-in-out infinite;
              animation: spinner 1s ease-in-out infinite; }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    background-color: #f34773; }
  75% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    background-color: #f3c464; }
  80% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    background-color: #eaf350;
    width: 10%; }
  100% {
    background-color: #44f325;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes spinner {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    background-color: #f34773; }
  75% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    background-color: #f3c464; }
  80% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    background-color: #eaf350;
    width: 10%; }
  100% {
    background-color: #44f325;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

.appear-enter {
  -webkit-transform: scale(0);
          transform: scale(0); }

.appear-enter-active {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 0.3s cubic-bezier(0.09, 1.2, 0.33, 1.18); }

.appear-exit {
  -webkit-transform: scale(1);
          transform: scale(1); }

.appear-exit-active {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.2s ease-in; }

.dm .item {
  border-bottom: 1px solid #484848; }
  .dm .item .name {
    color: white; }
  .dm .item input {
    color: white; }
  .dm .item .edit-cancel {
    color: #b7b7b7; }
  .dm .item .ls-fab {
    background-color: #484848; }

.dm .input-group {
  background-color: #454545; }
  .dm .input-group input {
    color: white; }
  .dm .input-group .tag-button {
    color: #cccccc; }
  .dm .input-group .tag-button-selected {
    color: white;
    background-color: #484848; }
  .dm .input-group .tags {
    background-color: #484848; }

.dm .ls-hamburger {
  background-color: #3f3f3f; }
  .dm .ls-hamburger .row {
    color: #f0f0f0;
    border-bottom: 1px solid #505050; }
  .dm .ls-hamburger .row-secondary {
    background-color: #383838; }
  .dm .ls-hamburger .ls-table-group h4 {
    color: white; }
  .dm .ls-hamburger .ls-table-group .first {
    border-top: 1px solid #505050; }
  .dm .ls-hamburger .saved-list-select {
    background-color: #3f3f3f;
    color: white !important; }
  .dm .ls-hamburger .saved-list {
    background-color: #313131;
    color: #f1f1f1;
    font-weight: normal; }
  .dm .ls-hamburger .add-list {
    box-shadow: unset; }
  .dm .ls-hamburger .selected-list-options .menu-sharks .menu-shark {
    border-color: transparent transparent #656565 transparent; }
  .dm .ls-hamburger .menu-selected-list-options {
    background-color: #656565;
    color: white; }

.dm .ls-header {
  background-color: #2f2f2f !important;
  border-bottom: 1px solid #585858; }

.dm .ls-empty .container {
  background-color: #353535; }

.dm .shark {
  border-color: #3f3f3f transparent transparent transparent; }

.dm .fade-enter-active {
  background-color: #4d4d4d; }

.dm .dialog {
  background-color: #484848; }
  .dm .dialog .dialog-title-container h1 {
    color: white; }
  .dm .dialog .dialog-content {
    background-color: #636363; }
    .dm .dialog .dialog-content p {
      color: white; }
    .dm .dialog .dialog-content input {
      background-color: unset;
      color: white; }
  .dm .dialog .button-1 {
    background-color: #484848;
    color: white !important; }

@media only screen and (min-width: 768px) {
  .ls-button {
    max-width: calc(300px - (16px * 2)); } }

.ls-button {
  background-color: white;
  color: black;
  text-align: center;
  padding: 8px 16px;
  border-radius: 16px;
  cursor: pointer; }

.width-80 {
  width: 80%; }

.ls-button-primary {
  color: white;
  background-color: #6523F3; }

.or {
  font-weight: 600;
  text-align: center;
  padding: 16px; }

.history-pop {
  cursor: pointer;
  position: fixed;
  right: 0;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
  width: 36px;
  height: 36px;
  color: white;
  z-index: 9;
  margin: 8px 16px 0 16px;
  border-radius: 12px; }

.history-content {
  position: absolute;
  background-color: white;
  left: 0;
  top: 52px;
  width: 100%; }
  .history-content .history-items-collective {
    overflow-y: scroll;
    max-height: calc(100vh - 52px); }
  .history-content .history-item-container {
    padding: 8px 16px;
    word-break: break-word; }
    .history-content .history-item-container span {
      margin-right: 6px; }
    .history-content .history-item-container .history-time svg {
      margin-right: 6px; }
    .history-content .history-item-container .history-item-details span {
      font-weight: bold; }
  .history-content h1 {
    margin-bottom: 0;
    padding: 0 16px 6px;
    border-bottom: 1px solid gainsboro;
    font-size: 20px; }

.history-transition-enter {
  -webkit-transform: scale(0, 0) translate(100%, 0px);
          transform: scale(0, 0) translate(100%, 0px); }

.history-transition-enter-active {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  transition: all 0.3s ease-out; }

.history-transition-exit {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0); }

.history-transition-exit-active {
  -webkit-transform: scale(0, 0) translate(100%, 0px);
          transform: scale(0, 0) translate(100%, 0px);
  transition: all 0.2s ease-in; }

.page-content {
  margin-top: 32px;
  padding: 32px; }

.person-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  margin-bottom: 8px; }
  .person-container .person {
    background-color: white;
    border-radius: 14px;
    padding: 10px 16px;
    -webkit-flex: 1 1;
            flex: 1 1; }
  .person-container .cross {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 14px;
    padding: 10px;
    background-color: white;
    width: 20px;
    height: 20px;
    margin-left: 8px; }

