$mainColor: #6523F3;
$secondaryColor: #E5DCF9;
$titleGrey: #ABABAB;
$backgroundGrey: #F3F3F3;
$textGrey: #838383;

$title-size-semi: 18px;
$semi-bold: 600;

.ls-hamburger {
  position: fixed;
  width: 60%;
  max-width: 450px;
  min-width: 240px;
  background: #F8F8F8;
  z-index: 5;
  height: 100%;
  transform: translateX(-100%);
  overflow: scroll;
  padding-bottom: 100px;
  transition: transform 360ms cubic-bezier(0, 0, 0.2, 1) 0ms
}

.ls-hamburger::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.font-semi-bold {
  font-weight: $semi-bold;
}

.hamburger-open {
  transform: translateX(0);
}

.ls-table-group {
  margin-top: 32px;

  .row {
    cursor: pointer;
    padding: 16px;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;

    svg {
      color: $textGrey;
    }
  }

  .row-secondary {
    background-color: #f3f3f3;
  }

  .with-icon {
    justify-content: space-between;
  }

  .first {
    border-top: 1px solid #f1f1f1;
  }

  h4 {
    margin-left: 16px;
    margin-bottom: 8px;
    color: $textGrey;
    font-size: 14px;
    text-transform: uppercase;
  }
}


.fade-menu-enter {
  height: 0;
}

.fade-menu-enter-active {
  height: 52px * 3;
  background-color: $backgroundGrey;
  transition: all 0.35s ease-out;
}

.fade-menu-exit {
  opacity: 1;
  height: 52px * 3;
}

.fade-menu-exit-active {
  height: 0;
  transition: all 0.2s ease-in;
}

.dialog-background {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  cursor: pointer;
}

.high-z-index {
  z-index: 15;
}

.medium-z-index {
  z-index: 10;
}

.low-z-index {
  z-index: 8;
}

.dialog {
  border-radius: 10px;
  overflow: hidden;
  transition: all 1s ease-in;
  width: 80%;
  position: fixed;
  max-width: 340px;
  background-color: $backgroundGrey;
  z-index: 16;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;

  .button-1 {
    opacity: 1;
    border: none;
  }

  h1 {
    font-size: 24px;
  }
  .dialog-title-container {
    text-align: center;
  }
  .dialog-content {
    padding: 16px;
    background-color: white;
    display: flex;
    flex-flow: column wrap;

    span {
      font-weight: bold;
    }

    p {
      padding: 16px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}

.fixed-full-width {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 16;
}

.loader-bar {
  width: 40px;
  height: 8px;
  position: relative;
  animation: load 1s infinite;
}

.dialog-title {
  input {
    border: none;
    font-size: medium;
    font-weight: bold;
    outline: none;
    padding: 10px 16px;
    margin-top: 10px;
    margin-bottom: 26px;
    background-color: $backgroundGrey;
    border-radius: 16px;
  }
}

.chrome-picker {
  margin: 0 auto;
}

@keyframes load {
  0% {
    left: -20px;
  }
  100% {
    left: 100%;
  }
}

.dialog-enter {
  top: 150%;
}

.dialog-enter-active {
  top: 50%;
  transition: all 0.3s ease-out;
}

.dialog-exit {
  top: 50%;
}

.dialog-exit-active {
  top: 150%;
  transition: all 0.2s ease-in;
}

.display-none {
  display: none;
}

.color-change-preview {
  width: 100px;
  height: 100px;
  margin: 16px auto;
  border-radius: 10px;
}



.saved-lists {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;

  .saved-list-flex {
    display: flex;
    flex-direction: row-reverse;
    max-width: 100%;
  }

  .saved-list {
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    font-weight: 600;
    margin: 6px 8px;
    padding: 12px 24px;
    color: white;
    border-radius: 8px;
    transition: box-shadow 0.2s ease-out;

    &:active {
      -webkit-box-shadow: inset 2px 3px 51px 30px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: inset 2px 3px 51px 30px rgba(0, 0, 0, 0.15);
      box-shadow: inset 2px 3px 51px 30px rgba(0, 0, 0, 0.15);
    }
  }

  .saved-list-select {
    background-color: #F8F8F8;
    border-radius: 50%;
    padding: 4px;
    font-size: 12px;
    position: absolute;
  }
}

.selected-list-options {
  box-sizing: border-box;
  background-color: white;
  font-weight: 600;
  overflow: hidden;
  margin-top: 6px;

  .menu-selected-list-options {
    text-align: center;
    padding: 16px;
    cursor: pointer;
  }
}

.menu-slide-enter {
  height: 0;
}

.menu-slide-enter-active {
  height: 51px;
  transition: all 0.3s ease-out;
}

.menu-slide-exit {
  height: 51px;
  bottom: 0;
}

.menu-slide-exit-active {
  height: 0;
  bottom: -20px;
  transition: all 0.3s cubic-bezier(0.73, 0.1, 0.56, 0.87);
}

.italic {
  font-style: italic;
}

@media only screen and (max-width: 400px) {
  .dialog {
    h1 {
      font-size: large;
    }
  }
}

.switch-group {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 9px 0 16px;
  margin-bottom: 16px;

  .switch-label {
    margin-right: auto;
    font-weight: 600;
  }
}

.dm .switch-label {
  color: white;
}
