@import "../../styling/base";

body {
  font-family: 'Work Sans', sans-serif;
  background-color: #F5F5F5;
  margin: 0;
  padding: 0;
}

input {
  font-family: 'Work Sans', sans-serif;
}

.gray {
  background-color: #F5F5F5;
}

.landing-page {
  overflow-x: hidden;

  h1 {
    font-size: 32px;
    margin: 0;
    padding: 64px 64px 0 64px;
    text-align: center;
  }

  h2 {
    padding: 0 32px 16px 32px;
    text-align: center;
    font-size: large;
    margin: 16px 0 0 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    #logo {
      margin-top: 48px;
      width: 100px;
    }
  }
}

.sharks {
  position: absolute;
  display: flex;
  max-width: 100%;
  overflow: hidden;

  .shark {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 34.6px 20px 0 20px;
    border-color: #F5F5F5 transparent transparent transparent;
  }
}

.button-1 {
  padding: 10px 24px;
  border-radius: 20px;
  text-transform: uppercase;
  border: 1px solid #E9E9E9;
  color: $mainColor;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: darken(white, 2%);
  }
}

.button-secondary {
  color: #636363;
}

@media only screen and (max-width: 374px) {
  .button-1 {
    font-size: small;
  }
}